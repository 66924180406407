import { hoursToSeconds, minutesToSeconds } from "date-fns";

export function isTimeWithinRange(params: {
  timeToCheck: {
    hour: number;
    minute: number;
  };
  start: {
    hour: number;
    minute: number;
  };
  end: {
    hour: number;
    minute: number;
  };
}) {
  return (
    hoursToSeconds(params.timeToCheck.hour) + minutesToSeconds(params.timeToCheck.minute) >=
      hoursToSeconds(params.start.hour) + minutesToSeconds(params.start.minute) &&
    hoursToSeconds(params.timeToCheck.hour) + minutesToSeconds(params.timeToCheck.minute) <=
      hoursToSeconds(params.end.hour) + minutesToSeconds(params.end.minute)
  );
}
