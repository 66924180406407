import { useState } from "react";

import { useInterval } from "./useInterval";

/**
 * A hook that returns the current date and updates it at a specified interval.
 *
 * @param updateIntervalMs - The interval in milliseconds at which to update the date. Defaults to 1000ms (1 second).
 * @returns The current date that updates at the specified interval.
 */
export function useCurrentDate(updateIntervalMs: number) {
  const [currentDate, setCurrentDate] = useState(new Date());

  useInterval(() => {
    setCurrentDate(new Date());
  }, updateIntervalMs);

  return currentDate;
}
