import { ZendeskSDK } from "capacitor-zendesk-sdk/dist/esm";

import { APP_V2_APP_EVENTS, logEvent } from "../analytics";
import { isCapacitorPlatform } from "../utils";
import { setZendeskMessagingUnread } from "./utils";

declare global {
  interface Window {
    zE: (x: string, y: string, z?: (callback: (token?: string) => void) => void) => void;
  }
}

export async function openZendeskMessaging() {
  logEvent(APP_V2_APP_EVENTS.ZENDESK_CHAT_OPENED);
  setZendeskMessagingUnread(false);

  if (isCapacitorPlatform()) {
    await ZendeskSDK.showMessaging();
  } else {
    window.zE("messenger", "open");
    window.zE("messenger", "show");
    window.zE("messenger:on", "close", () => {
      window.zE("messenger", "hide");
    });
  }
}
