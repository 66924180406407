/**
 * Returns a new set containing the elements that are present in both input sets.
 * This can be removed if we upgrade to TypeScript 5.5.
 *
 * @param set1 - The first set.
 * @param set2 - The second set.
 * @returns A new set containing the intersection of the two input sets.
 */
export function intersection<T>(set1: Set<T>, set2: Set<T>): Set<T> {
  const result = new Set<T>();
  for (const element of set1) {
    if (set2.has(element)) {
      result.add(element);
    }
  }

  return result;
}
