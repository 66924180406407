import { Browser } from "@capacitor/browser";

/**
 * Opens a URL in the browser with consistent styling
 *
 * @param url - The URL to open in the browser
 * @returns A promise that resolves when the browser is opened
 */
export async function openBrowser(url: string) {
  await Browser.open({
    url,
    toolbarColor: "#001529",
    presentationStyle: "fullscreen",
  });
}
