const ZENDESK_MESSAGING_UNREAD_STATUS_KEY = "zendesk_messaging_unread_status";

export function isZendeskMessagingUnread() {
  const status = localStorage.getItem(ZENDESK_MESSAGING_UNREAD_STATUS_KEY);
  return status === "true";
}

export function setZendeskMessagingUnread(unread: boolean) {
  localStorage.setItem(ZENDESK_MESSAGING_UNREAD_STATUS_KEY, unread.toString());
}
