import { isDefined } from "@clipboard-health/util-ts";
import { useEffect, useRef } from "react";

export function useInterval(callback: () => void, delay: number | undefined) {
  const savedCallback = useRef(callback);

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    if (!isDefined(delay)) {
      return;
    }

    const id = setInterval(() => {
      savedCallback.current();
    }, delay);

    // eslint-disable-next-line consistent-return
    return () => {
      clearInterval(id);
    };
  }, [delay]);
}
